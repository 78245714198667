const workExperienceData = [
  {
    company: "Telkom University",
    date: "Sep 2019 - Nov 2023",
    status: "Software Engineering",
    description: "Graduate with cum laude predicate",
  },
  {
    company: "Medikacom Vocational High School",
    date: "Feb 2016 - Aug 2019",
    status: "Software Engineering",
    description: "Awarded As Best Student of Software Engineering at 2019",
  },
];

export default workExperienceData;
